import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { getSeasonFaild, getSeasonSuccess } from "../actions/season";
import { baseUrl } from '../../utils/index';


//fetch season from database
function getSeasonApi(){
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null; 

  return axios.get(`${baseUrl}/months?na=${token ==='admin' ? true : false}`,{headers: {Authorization: `Bearer ${token}`}});
}
function* getSeason( token  ) { 
  try {
    const getResponse = yield call(getSeasonApi);
    if(getResponse.data.error)
      yield put(getSeasonFaild(getResponse.data.msg));
    else yield put(getSeasonSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getSeasonFaild(error));
  }
}

function* fetchSeason(){
  yield takeLatest("GET_SEASON_REQUESTED", getSeason);
}

export  {fetchSeason};
