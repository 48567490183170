import * as type from "../types";
export const addContactRequested = (user) => ({
  type: type.ADD_CONTACT_REQUESTED,
  payload: user,
});
export const addContactSuccess = (u) => ({
  type: type.ADD_CONTACT_SUCCESS,
  payload: u,
});
export const addContactFaild = (errorMsg) => ({
  type: type.ADD_CONTACT_FAILD,
  payload: errorMsg,
});
