//for authentication
export const AUTHENTICATION_REQUESTED = "AUTHENTICATION_REQUESTED";
export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILD = "AUTHENTICATION_FAILD";
export const CREATE_ACCOUNT_REQUESTED = "CREATE_ACCOUNT_REQUESTED";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";
export const CREATE_ACCOUNT_FAILD = "CREATE_ACCOUNT_FAILD";
// special user
export const SPECIAL_USER_ACCOUNT_REQUESTED = "SPECIAL_USER_ACCOUNT_REQUESTED";
export const SPECIAL_USER_ACCOUNT_SUCCESS = "SPECIAL_USER_ACCOUNT_SUCCESS";
export const SPECIAL_USER_ACCOUNT_FAILD = "SPECIAL_USER_ACCOUNT_FAILD";
// for forget password
export const FORGET_PASSWORD_REQUESTED = "FORGET_PASSWORD_REQUESTED";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILD = "FORGET_PASSWORD_FAILD";
// for check phone number
export const CHECK_PHONENUMBER_REQUESTED = "CHECK_PHONENUMBER_REQUESTED";
export const CHECK_PHONENUMBER_SUCCESS = "CHECK_PHONENUMBER_SUCCESS";
export const CHECK_PHONENUMBER_FAILD = "CHECK_PHONENUMBER_FAILD";
//for reset password
export const RESET_PASSWORD_REQUESTED = "RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILD = "RESET_PASSWORD_FAILD";

//for verify account
export const VERIFY_ACCOUNT_REQUESTED = "VERIFY_ACCOUNT_REQUESTED";
export const VERIFY_ACCOUNT_SUCCESS = "VERIFY_ACCOUNT_SUCCESS";
export const VERIFY_ACCOUNT_FAILD = "VERIFY_ACCOUNT_FAILD";
// for category
export const ADD_CATEGORY_REQUESTED = "ADD_CATEGORY_REQUESTED";
export const ADD_CATEGORY_SUCCESS = "ADD_CATEGORY_SUCCESS";
export const ADD_CATEGORY_FAILD = "ADD_CATEGORY_FAILD";
export const GET_CATEGORY_REQUESTED = "GET_CATEGORY_REQUESTED";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAILD = "GET_CATEGORY_FAILD";
export const UPDATE_CATEGORY_REQUESTED = "UPDATE_CATEGORY_REQUESTED";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILD = "UPDATE_CATEGORY_FAILD";
export const DELETE_CATEGORY_REQUESTED = "DELETE_CATEGORY_REQUESTED";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILD = "DELETE_CATEGORY_FAILD";
// for sub category
export const ADD_SUB_CATEGORY_REQUESTED = "ADD_SUB_CATEGORY_REQUESTED";
export const ADD_SUB_CATEGORY_SUCCESS = "ADD_SUB_CATEGORY_SUCCESS";
export const ADD_SUB_CATEGORY_FAILD = "ADD_SUB_CATEGORY_FAILD";
export const GET_SUB_CATEGORY_REQUESTED = "GET_SUB_CATEGORY_REQUESTED";
export const GET_SUB_CATEGORY_SUCCESS = "GET_SUB_CATEGORY_SUCCESS";
export const GET_SUB_CATEGORY_FAILD = "GET_SUB_CATEGORY_FAILD";
export const GET_SUB_CATEGORY_BYID_REQUESTED =
  "GET_SUB_CATEGORY_BYID_REQUESTED";
export const GET_SUB_CATEGORY_BYID_SUCCESS = "GET_SUB_CATEGORY_BYID_SUCCESS";
export const GET_SUB_CATEGORY_BYID_FAILD = "GET_SUB_CATEGORY_BYID_FAILD";
export const UPDATE_SUB_CATEGORY_REQUESTED = "UPDATE_SUB_CATEGORY_REQUESTED";
export const UPDATE_SUB_CATEGORY_SUCCESS = "UPDATE_SUB_CATEGORY_SUCCESS";
export const UPDATE_SUB_CATEGORY_FAILD = "UPDATE_SUB_CATEGORY_FAILD";
export const DELETE_SUB_CATEGORY_REQUESTED = "DELETE_SUB_CATEGORY_REQUESTED";
export const DELETE_SUB_CATEGORY_SUCCESS = "DELETE_SUB_CATEGORY_SUCCESS";
export const DELETE_SUB_CATEGORY_FAILD = "DELETE_SUB_CATEGORY_FAILD";

// for resource
export const ADD_RESOURCE_REQUESTED = "ADD_RESOURCE_REQUESTED";
export const ADD_RESOURCE_SUCCESS = "ADD_RESOURCE_SUCCESS";
export const ADD_RESOURCE_FAILD = "ADD_RESOURCE_FAILD";
export const GET_RESOURCE_REQUESTED = "GET_RESOURCE_REQUESTED";
export const GET_RESOURCE_SUCCESS = "GET_RESOURCE_SUCCESS";
export const GET_RESOURCE_FAILD = "GET_RESOURCE_FAILD";
export const UPDATE_RESOURCE_REQUESTED = "UPDATE_RESOURCE_REQUESTED";
export const UPDATE_RESOURCE_SUCCESS = "UPDATE_RESOURCE_SUCCESS";
export const UPDATE_RESOURCE_FAILD = "UPDATE_RESOURCE_FAILD";
export const DELETE_RESOURCE_REQUESTED = "DELETE_RESOURCE_REQUESTED";
export const DELETE_RESOURCE_SUCCESS = "DELETE_RESOURCE_SUCCESS";
export const DELETE_RESOURCE_FAILD = "DELETE_RESOURCE_FAILD";
// for post
export const ADD_INTERVENTION_REQUESTED = "ADD_INTERVENTION_REQUESTED";
export const ADD_INTERVENTION_SUCCESS = "ADD_INTERVENTION_SUCCESS";
export const ADD_INTERVENTION_FAILD = "ADD_INTERVENTION_FAILD";
export const ADD_VIDEO_REQUESTED = "ADD_VIDEO_REQUESTED";
export const ADD_VIDEO_SUCCESS = "ADD_VIDEO_SUCCESS";
export const ADD_VIDEO_FAILD = "ADD_VIDEO_FAILD";
export const GET_INTERVENTION_REQUESTED = "GET_INTERVENTION_REQUESTED";
export const GET_INTERVENTION_SUCCESS = "GET_INTERVENTION_SUCCESS";
export const GET_INTERVENTION_FAILD = "GET_INTERVENTION_FAILD";
export const UPDATE_INTERVENTION_REQUESTED = "UPDATE_INTERVENTION_REQUESTED";
export const UPDATE_INTERVENTION_SUCCESS = "UPDATE_INTERVENTION_SUCCESS";
export const UPDATE_INTERVENTION_FAILD = "UPDATE_INTERVENTION_FAILD";
export const DELETE_INTERVENTION_REQUESTED = "DELETE_INTERVENTION_REQUESTED";
export const DELETE_INTERVENTION_SUCCESS = "DELETE_INTERVENTION_SUCCESS";
export const DELETE_INTERVENTION_FAILD = "DELETE_INTERVENTION_FAILD";
//for specific details
export const GET_INTERVENTION_BY_REQUESTED = "GET_INTERVENTION_BY_REQUESTED";
export const GET_INTERVENTION_BY_SUCCESS = "GET_INTERVENTION_BY_SUCCESS";
export const GET_INTERVENTION_BY_FAILD = "GET_INTERVENTION_BY_FAILD";
//for Activity by sub category id
export const GET_ACTIVITY_REQUESTED = "GET_ACTIVITY_REQUESTED";
export const GET_ACTIVITY_SUCCESS = "GET_ACTIVITY_SUCCESS";
export const GET_ACTIVITY_FAILD = "GET_ACTIVITY_FAILD";
//for Activity by month id
export const GET_ACTIVITY_BY_SEASON_REQUESTED =
  "GET_ACTIVITY_BY_SEASON_REQUESTED";
export const GET_ACTIVITY_BY_SEASON_SUCCESS = "GET_ACTIVITY_BY_SEASON_SUCCESS";
export const GET_ACTIVITY_BY_SEASON_FAILD = "GET_ACTIVITY_BY_SEASON_FAILD";
// for language
export const ADD_LANGUAGE_REQUESTED = "ADD_LANGUAGE_REQUESTED";
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const ADD_LANGUAGE_FAILD = "ADD_LANGUAGE_FAILD";
export const GET_LANGUAGE_REQUESTED = "GET_LANGUAGE_REQUESTED";
export const GET_LANGUAGE_SUCCESS = "GET_LANGUAGE_SUCCESS";
export const GET_LANGUAGE_FAILD = "GET_LANGUAGE_FAILD";
export const UPDATE_LANGUAGE_REQUESTED = "UPDATE_LANGUAGE_REQUESTED";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAILD = "UPDATE_LANGUAGE_FAILD";
export const DELETE_LANGUAGE_REQUESTED = "DELETE_LANGUAGE_REQUESTED";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAILD = "DELETE_LANGUAGE_FAILD";
// for season
export const GET_SEASON_REQUESTED = "GET_SEASON_REQUESTED";
export const GET_SEASON_SUCCESS = "GET_SEASON_SUCCESS";
export const GET_SEASON_FAILD = "GET_SEASON_FAILD";
// for contact
export const ADD_CONTACT_REQUESTED = "ADD_CONTACT_REQUESTED";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const ADD_CONTACT_FAILD = "ADD_CONTACT_FAILD";

// for create news
export const ADD_NEWS_REQUESTED = "ADD_NEWS_REQUESTED";
export const ADD_NEWS_SUCCESS = "ADD_NEWS_SUCCESS";
export const ADD_NEWS_FAILD = "ADD_NEWS_FAILD";
export const GET_NEWS_REQUESTED = "GET_NEWS_REQUESTED";
export const GET_NEWS_SUCCESS = "GET_NEWS_SUCCESS";
export const GET_NEWS_FAILD = "GET_NEWS_FAILD";
export const UPDATE_NEWS_REQUESTED = "UPDATE_NEWS_REQUESTED";
export const UPDATE_NEWS_SUCCESS = "UPDATE_NEWS_SUCCESS";
export const UPDATE_NEWS_FAILD = "UPDATE_NEWS_FAILD";
export const DELETE_NEWS_REQUESTED = "DELETE_NEWS_REQUESTED";
export const DELETE_NEWS_SUCCESS = "DELETE_NEWS_SUCCESS";
export const DELETE_NEWS_FAILD = "DELETE_NEWS_FAILD";
// for partner
export const ADD_PARTNER_REQUESTED = "ADD_PARTNER_REQUESTED";
export const ADD_PARTNER_SUCCESS = "ADD_PARTNER_SUCCESS";
export const ADD_PARTNER_FAILD = "ADD_PARTNER_FAILD";
export const GET_PARTNER_REQUESTED = "GET_PARTNER_REQUESTED";
export const GET_PARTNER_SUCCESS = "GET_PARTNER_SUCCESS";
export const GET_PARTNER_FAILD = "GET_PARTNER_FAILD";
export const UPDATE_PARTNER_REQUESTED = "UPDATE_PARTNER_REQUESTED";
export const UPDATE_PARTNER_SUCCESS = "UPDATE_PARTNER_SUCCESS";
export const UPDATE_PARTNER_FAILD = "UPDATE_PARTNER_FAILD";
export const DELETE_PARTNER_REQUESTED = "DELETE_PARTNER_REQUESTED";
export const DELETE_PARTNER_SUCCESS = "DELETE_PARTNER_SUCCESS";
export const DELETE_PARTNER_FAILD = "DELETE_PARTNER_FAILD";

// for channel
export const ADD_CHANNEL_REQUESTED = "ADD_CHANNEL_REQUESTED";
export const ADD_CHANNEL_SUCCESS = "ADD_CHANNEL_SUCCESS";
export const ADD_CHANNEL_FAILD = "ADD_CHANNEL_FAILD";
export const GET_CHANNEL_REQUESTED = "GET_CHANNEL_REQUESTED";
export const GET_CHANNEL_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_FAILD = "GET_CHANNEL_FAILD";
export const GET_CHANNEL_BYID_REQUESTED = "GET_CHANNEL_REQUESTED";
export const GET_CHANNEL_BYID_SUCCESS = "GET_CHANNEL_SUCCESS";
export const GET_CHANNEL_BYID_FAILD = "GET_CHANNEL_FAILD";

export const UPDATE_CHANNEL_REQUESTED = "UPDATE_CHANNEL_REQUESTED";
export const UPDATE_CHANNEL_SUCCESS = "UPDATE_CHANNEL_SUCCESS";
export const UPDATE_CHANNEL_FAILD = "UPDATE_CHANNEL_FAILD";
export const DELETE_CHANNEL_REQUESTED = "DELETE_CHANNEL_REQUESTED";
export const DELETE_CHANNEL_SUCCESS = "DELETE_CHANNEL_SUCCESS";
export const DELETE_CHANNEL_FAILD = "DELETE_CHANNEL_FAILD";

///feedback
export const GET_FEEDBACK_REQUESTED = "GET_FEEDBACK_REQUESTED";
export const GET_FEEDBACK_SUCCESS = "GET_FEEDBACK_SUCCESS";
export const GET_FEEDBACK_FAILD = "GET_FEEDBACK_FAILD";
export const DELETE_FEEDBACK_REQUESTED = "DELETE_FEEDBACK_REQUESTED";
export const DELETE_FEEDBACK_SUCCESS = "DELETE_FEEDBACK_SUCCESS";
export const DELETE_FEEDBACK_FAILD = "DELETE_FEEDBACK_FAILD";
///fetch total user
export const GET_TOTALUSER_REQUESTED = "GET_TOTALUSER_REQUESTED";
export const GET_TOTALUSER_SUCCESS = "GET_TOTALUSER_SUCCESS";
export const GET_TOTALUSER_FAILD = "GET_TOTALUSER_FAILD";
