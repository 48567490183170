import * as type from "../types";
export const addChannelRequested = (user) => ({
  type: type.ADD_CHANNEL_REQUESTED,
  payload: user,
});
export const addChannelSuccess = (u) => ({
  type: type.ADD_CHANNEL_SUCCESS,
  payload: u,
});
export const addChannelFaild = (errorMsg) => ({
  type: type.ADD_CHANNEL_FAILD,
  payload: errorMsg,
});
export const getChannelRequested = () => ({
  type: type.GET_CHANNEL_REQUESTED,
});
export const getChannelSuccess = (chann) => ({
  type: type.GET_CHANNEL_SUCCESS,
  payload: chann,
});
export const getChannelFaild = (errorMsg) => ({
  type: type.GET_CHANNEL_FAILD,
  error: errorMsg,
});
export const deleteChannelRequested = (del) => ({
  type: type.DELETE_CHANNEL_REQUESTED,
  payload: del,
});
export const deleteChannelSuccess = (del) => ({
  type: type.DELETE_CHANNEL_SUCCESS,
  payload: del,
});
export const deleteChannelFaild = (errorMsg) => ({
  type: type.DELETE_CHANNEL_FAILD,
  payload: errorMsg,
});

export const updateChannelRequested = (update) => ({
  type: type.UPDATE_CHANNEL_REQUESTED,
  payload: update,
});
export const updateChannelSuccess = (update) => ({
  type: type.UPDATE_CHANNEL_SUCCESS,
  payload: update,
});
export const updateChannelFaild = (errorMsg) => ({
  type: type.UPDATE_CHANNEL_FAILD,
  payload: errorMsg,
});
