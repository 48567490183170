import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addContactSuccess,
  addContactFaild,

} from "../actions/contact";

import { baseUrl } from '../../utils/index';
//add category to database
function addContactApi({ values }) {
  return axios.post(`${baseUrl}/contact/add-new-message`, values);
}
function* addContact({ payload: { values } }) {
  try {
    const contactResponse = yield call(addContactApi, { values });
    if (contactResponse.data.error)
      yield put(addContactFaild(contactResponse.data.msg));
    else yield put(addContactSuccess(contactResponse.data.msg));
  } catch (error) {
    yield put(addContactFaild(error));
  }
}

function* addContac() {
  yield takeLatest("ADD_CONTACT_REQUESTED", addContact);
}
export { addContac };
