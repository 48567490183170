import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';


i18n
.use(HttpApi)
.use(LanguageDetector)
.use(initReactI18next)
  .init({
    supportedLngs: ['eng', 'amh', 'or','kf'],

    /* When react i18next not finding any language to as default in borwser */
    lng: localStorage.getItem("code") !== null ? localStorage.getItem("code"): 'eng' ,
    fallbackLng: "eng",
    debug: false,
    // Options for language detector
    detection: {
      order: ['path', 'cookie', 'htmlTag',],
      caches: ['cookie'],
    },
    // react: { useSuspense: false },
    backend: {
      loadPath: '/locales/{{lng}}/translation.json',
      
    },
   
  })

  


export default i18n;