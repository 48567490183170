import { takeLatest, put, call } from "redux-saga/effects";
import {
  forgetPasswordFaild,
  forgetPasswordSuccess,
  checkPhoneNumberFaild,
  checkPhoneNumberSuccess,
  resetPasswordFaild,
  resetPasswordSuccess,
  verifyAccountFaild,
  verifyAccountSuccess,
} from "../actions/users";

import axios from "axios";
import { baseUrl } from "../../utils/index";

function forgetPasswordApi({ values }) {
  return axios.put(`${baseUrl}/user/forgetPassword-token`, values);
}
function* forgetPass({ payload: { values } }) {
  try {
    const response = yield call(forgetPasswordApi, { values });
    if (response.data.error) yield put(forgetPasswordFaild(response.data.msg));
    else {
      yield put(forgetPasswordSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(forgetPasswordFaild(error));
  }
}
//Check phone number register or not register
function checkPhoneNumberApi({ phone }) {
  return axios.post(`${baseUrl}/user/checkphoneNumber`, phone);
}
function* checkPhone({ payload: { phone } }) {
  try {
    const response = yield call(checkPhoneNumberApi, { phone });
    if (response.data.error)
      yield put(checkPhoneNumberFaild(response.data.msg));
    else {
      yield put(checkPhoneNumberSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(checkPhoneNumberFaild(error));
  }
}
function resetPasswordApi({ data }) {
  if (data.email) return axios.put(`${baseUrl}/user/resetPassword`, data);
  else return axios.put(`${baseUrl}/user/resetPassword-otp`, data);
}
function* resetPass({ payload: { data } }) {
  try {
    const response = yield call(resetPasswordApi, { data });
    if (response.data.error) yield put(resetPasswordFaild(response.data.msg));
    else {
      yield put(resetPasswordSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(resetPasswordFaild(error));
  }
}
////////////////////////////////////////////////
function verifyAccountApi({ data }) {
  if (data.email) return axios.put(`${baseUrl}/user/verifyAccount`, data);
  else return axios.put(`${baseUrl}/user/verifyAccount-otp`, data);
}
function* verifyAcc({ payload: { data } }) {
  try {
    const response = yield call(verifyAccountApi, { data });
    if (response.data.error) yield put(verifyAccountFaild(response.data.msg));
    else {
      yield put(verifyAccountSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(verifyAccountFaild(error));
  }
}

function* forgetPassword() {
  yield takeLatest("FORGET_PASSWORD_REQUESTED", forgetPass);
}
function* checkPhoneNumber() {
  yield takeLatest("CHECK_PHONENUMBER_REQUESTED", checkPhone);
}
function* resetPassword() {
  yield takeLatest("RESET_PASSWORD_REQUESTED", resetPass);
}
function* verifyAccount() {
  yield takeLatest("VERIFY_ACCOUNT_REQUESTED", verifyAcc);
}

export {
  forgetPassword,
  checkPhoneNumber,
  resetPassword,
  verifyAccount,
};
