import { takeLatest,put,call} from 'redux-saga/effects'
import { createAccountSuccess,createAccountFaild } from '../actions/users'
import { baseUrl } from '../../utils/index';
import axios from "axios";
function getApi({values}){//   
    return  axios.post(`${baseUrl}/user/createAccount`,values);
}
function* createAcc({ payload: { values } }) { 
    try{
        const newAccount=yield call(getApi,{values});
        if(newAccount.data.error)
        yield put(createAccountFaild(newAccount.data.msg));
        else
        yield put(createAccountSuccess(newAccount.data.msg));
    }
    catch(error){
       yield put(createAccountFaild(error));
    }
}
function* createAccount(){
    yield takeLatest('CREATE_ACCOUNT_REQUESTED',createAcc);
}
export default createAccount;