import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addPartnerFaild,
  addPartnerSuccess,
  deletePartnerFaild,
  deletePartnerSuccess,
  getPartnerFaild,
  getPartnerSuccess,
  updatePartnerFaild,
  updatePartnerSuccess,
} from "../actions/partner";
import { baseUrl } from "../../utils/index";

//add category to database
function addPartnerApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.post(`${baseUrl}/partners/`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addPartner({ payload: { values } }) {
  try {
    const response = yield call(addPartnerApi, { values });
    if (response.data.error) yield put(addPartnerFaild(response.data.msg));
    else yield put(addPartnerSuccess(response.data.msg));
  } catch (error) {
    yield put(addPartnerFaild(error));
  }
}
//fetch partner from database
function getPartnerApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/partners/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getPartner() {
  try {
    const response = yield call(getPartnerApi);
    if (response.data.error) yield put(getPartnerFaild(response?.data?.msg));
    else yield put(getPartnerSuccess(response?.data?.data));
  } catch (error) {
    yield put(getPartnerFaild(error?.message));
  }
}
function updatePartnerApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/partners/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updatePartner({ payload: { values, id } }) {
  try {
    const response = yield call(updatePartnerApi, { values, id });
    if (response.data.error) yield put(updatePartnerFaild(response.data.msg));
    else yield put(updatePartnerSuccess(response.data.msg));
  } catch (error) {
    yield put(updatePartnerFaild(error));
  }
}

function delPartnerApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/partners/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deletePartner({ payload: { id } }) {
  try {
    const response = yield call(delPartnerApi, { id });
    if (response.data.error) {
      yield put(deletePartnerFaild(response.data.msg));
    } else {
      yield put(deletePartnerSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(deletePartnerFaild(error));
  }
}
function* createPartner() {
  yield takeLatest("ADD_PARTNER_REQUESTED", addPartner);
}
function* fetchPartner() {
  yield takeLatest("GET_PARTNER_REQUESTED", getPartner);
}
function* updatedPartner() {
  yield takeLatest("UPDATE_PARTNER_REQUESTED", updatePartner);
}
function* deletedPartner() {
  yield takeLatest("DELETE_PARTNER_REQUESTED", deletePartner);
}
export { createPartner, fetchPartner, updatedPartner, deletedPartner };
