import { takeLatest,put,call} from 'redux-saga/effects'
import { specialUserAccountSuccess,specialUserAccountFaild } from '../actions/users'
import { baseUrl } from '../../utils/index';
import axios from "axios";
function getApi({values}){//   
    return  axios.post(`${baseUrl}/user/specialUser`,values);  
}
function* specialUserAcc({ payload: { values } }) { 
    try{
        const newAccount=yield call(getApi,{values});
        if(newAccount.data.error)
        yield put(specialUserAccountFaild(newAccount.data.msg));
        else
        yield put(specialUserAccountSuccess(newAccount.data.msg));
    }
    catch(error){
       yield put(specialUserAccountFaild(error));
    }
}
function* specialUserAccount(){
    yield takeLatest('SPECIAL_USER_ACCOUNT_REQUESTED',specialUserAcc);
}
export default specialUserAccount;