import { lazy } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

const PrivateRoute = lazy(() => import("./Navigation/PrivateRoute"));
const Headers = lazy(() => import("./Navigation/Headers"));
const UserPrivateRoute = lazy(() => import("./Navigation/UserPrivateRoute"));
const AdminPrivateRoute = lazy(() => import("./Navigation/AdminPrivateRoute"));
const AdminSidebar = lazy(() => import("./Navigation/AdminSidebar"));
const Footers = lazy(() => import("./Navigation/Footers"));

const AddText = lazy(() => import("./pages/adminPages/AddText"));
const AddVideo = lazy(() => import("./pages/adminPages/AddVideo"));
const AdminCategory = lazy(() => import("./pages/adminPages/AdminCategory"));
const Language = lazy(() => import("./pages/adminPages/Language"));
const AdminSubCategory = lazy(() =>
  import("./pages/adminPages/AdminSubCategory")
);
const Resource = lazy(() => import("./pages/adminPages/Resource"));
const TextVideo = lazy(() => import("./pages/adminPages/TextVideo"));
const News = lazy(() => import("./pages/adminPages/News"));
const Partners = lazy(() => import("./pages/adminPages/Partners"));
const Channels = lazy(() => import("./pages/adminPages/Channels"));
const UpdateText = lazy(() => import("./pages/adminPages/UpdateText"));
const UserAccount = lazy(() => import("./pages/adminPages/UserAccount"));
const Feedback = lazy(() => import("./pages/adminPages/Feedback"));

const Login = lazy(() => import("./pages/auth/Login/Login"));
const Register = lazy(() => import("./pages/auth/Register/Register"));
const Forgot = lazy(() => import("./pages/auth/Forgot/Forgot"));
const ResetPasswordByEmail = lazy(() =>
  import("./pages/auth/ResetPassword/ResetPasswordByEmail")
);
const ResetPasswordByOTP = lazy(() =>
  import("./pages/auth/ResetPassword/ResetPasswordByOTP")
);
const PasswordChanged = lazy(() =>
  import("./pages/auth/ResetPassword/PasswordChanged")
);
const Verify = lazy(() => import("./pages/auth/Verification/Verify"));
const VerifyOtp = lazy(() => import("./pages/auth/Verification/VerifyOtp"));
const EmailVerify = lazy(() => import("./pages/auth/Verification/EmailVerify"));
const OtpVerify = lazy(() => import("./pages/auth/Verification/OtpVerify"));

const HomePage = lazy(() => import("./pages/userPages/HomePage"));
const Category = lazy(() => import("./pages/userPages/Category"));
const SearchedActivities = lazy(() =>
  import("./pages/userPages/SearchedActivities")
);
const Activity = lazy(() => import("./pages/userPages/Activity"));
const SubCategory = lazy(() => import("./pages/userPages/SubCategory"));
const Intervention = lazy(() => import("./pages/userPages/Intervention"));
const Video = lazy(() => import("./pages/userPages/Video"));
const Contact = lazy(() => import("./pages/userPages/Contact"));
const About = lazy(() => import("./pages/userPages/About"));
const TermCondition = lazy(() => import("./pages/userPages/TermCondition"));
const NotFound = lazy(() => import("./pages/userPages/NotFound"));

function App() {
  const users = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo"))?.user?.user_role
    : null;

  // Check if the user role is admin, super_admin, or other_admin
  const isAdmin = ["admin", "super_admin", "other_admin"].includes(users);

  const location = useLocation();
  const protectedHeader = () => {
    switch (location.pathname) {
      case "/register":
        return null;
      case "/login":
        return null;
      case "/forgot":
        return null;
      case "/verify-by-email":
        return null;
      case "/verify-by-otp":
        return null;
      case "/account-verify-by-email/:token":
        return null;
      case "/account-verify-by-otp":
        return null;
      case "/reset-by-otp":
        return null;
      case "/changePassword":
        return null;
      default:
        return <Headers />;
    }
  };
  const protectedFooter = () => {
    switch (location.pathname) {
      case "/register":
        return null;
      case "/login":
        return null;
      case "/forgot":
        return null;
      case "/verify-by-email":
        return null;
      case "/verify-by-otp":
        return null;
      case "/account-verify-by-email/:token":
        return null;
      case "/account-verify-by-otp":
        return null;
      case "/reset-by-otp":
        return null;
      case "/changePassword":
        return null;
      default:
        return <Footers />;
    }
  };

  return (
    <>
      {!isAdmin && protectedHeader()}
      <div className="flex">
        {isAdmin && <AdminSidebar />}
        <div className="w-full flex flex-col">
          <Routes>
            <Route
              path="/"
              element={
                isAdmin ? (
                  <PrivateRoute>
                    <HomePage />
                  </PrivateRoute>
                ) : (
                  <HomePage />
                )
              }
            ></Route>
            <Route
              path="/login"
              element={
                <PrivateRoute>
                  <Login />
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/article-catogory"
              element={
                <UserPrivateRoute>
                  <Category />
                </UserPrivateRoute>
              }
            ></Route>
            <Route
              path="/sub-catogory/:id"
              element={
                <UserPrivateRoute>
                  <SubCategory />
                </UserPrivateRoute>
              }
            ></Route>
            <Route
              path="/activity/:id"
              element={
                <UserPrivateRoute>
                  <Activity />
                </UserPrivateRoute>
              }
            ></Route>
            <Route
              path="/search-activity/:id"
              element={
                <UserPrivateRoute>
                  <SearchedActivities />
                </UserPrivateRoute>
              }
            ></Route>
            <Route
              path="/intervention/:id"
              element={
                <UserPrivateRoute>
                  <Intervention />
                </UserPrivateRoute>
              }
            ></Route>
            <Route
              path="/categories"
              element={
                <AdminPrivateRoute>
                  <AdminCategory />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/sub_categories"
              element={
                <AdminPrivateRoute>
                  <AdminSubCategory />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/text-video"
              element={
                <AdminPrivateRoute>
                  <TextVideo />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/news"
              element={
                <AdminPrivateRoute>
                  <News />
                </AdminPrivateRoute>
              }
            ></Route>

            <Route
              path="/partners"
              element={
                <AdminPrivateRoute>
                  <Partners />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/channels"
              element={
                <AdminPrivateRoute>
                  <Channels />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/feedback"
              element={
                <AdminPrivateRoute>
                  <Feedback />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/resources"
              element={
                <AdminPrivateRoute>
                  <Resource />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/language"
              element={
                <AdminPrivateRoute>
                  <Language />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/users"
              element={
                <AdminPrivateRoute>
                  <UserAccount />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/add_text"
              element={
                <AdminPrivateRoute>
                  <AddText />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/add_video"
              element={
                <AdminPrivateRoute>
                  <AddVideo />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route
              path="/updates/:id"
              element={
                <AdminPrivateRoute>
                  <UpdateText />
                </AdminPrivateRoute>
              }
            ></Route>
            <Route path="/" element={<HomePage />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/forgot" element={<Forgot />}></Route>
            <Route path="/video/:id" element={<Video />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route
              path="/reset/:token"
              element={<ResetPasswordByEmail />}
            ></Route>
            <Route
              path="/reset-by-otp"
              element={<ResetPasswordByOTP />}
            ></Route>
            <Route
              path="/account-verify-by-email/:token"
              element={<Verify />}
            ></Route>
            <Route
              path="/account-verify-by-otp"
              element={<VerifyOtp />}
            ></Route>
            <Route path="/verify-by-email" element={<EmailVerify />}></Route>
            <Route path="/verify-by-otp" element={<OtpVerify />}></Route>
            <Route path="changePassword" element={<PasswordChanged />}></Route>
            <Route
              path="/term-and-condition"
              element={<TermCondition />}
            ></Route>
            <Route path="un-authorized" element={<NotFound />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </div>
      </div>
      {!isAdmin && protectedFooter()}
    </>
  );
}
export default App;
