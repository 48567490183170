import * as type from "../types";
export const addPartnerRequested = (user) => ({
  type: type.ADD_PARTNER_REQUESTED,
  payload: user,
});
export const addPartnerSuccess = (u) => ({
  type: type.ADD_PARTNER_SUCCESS,
  payload: u,
});
export const addPartnerFaild = (errorMsg) => ({
  type: type.ADD_PARTNER_FAILD,
  payload: errorMsg,
});
export const getPartnerRequested = () => ({
  type: type.GET_PARTNER_REQUESTED,
});
export const getPartnerSuccess = (cate) => ({
  type: type.GET_PARTNER_SUCCESS,
  payload: cate,
});
export const getPartnerFaild = (errorMsg) => ({
  type: type.GET_PARTNER_FAILD,
  error: errorMsg,
});
export const deletePartnerRequested = (del) => ({
  type: type.DELETE_PARTNER_REQUESTED,
  payload: del,
});
export const deletePartnerSuccess = (del) => ({
  type: type.DELETE_PARTNER_SUCCESS,
  payload: del,
});
export const deletePartnerFaild = (errorMsg) => ({
  type: type.DELETE_PARTNER_FAILD,
  payload: errorMsg,
});

export const updatePartnerRequested = (updatePartner) => ({
  type: type.UPDATE_PARTNER_REQUESTED,
  payload: updatePartner,
});
export const updatePartnerSuccess = (update) => ({
  type: type.UPDATE_PARTNER_SUCCESS,
  payload: update,
});
export const updatePartnerFaild = (errorMsg) => ({
  type: type.UPDATE_PARTNER_FAILD,
  payload: errorMsg,
});
