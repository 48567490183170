import { all } from "redux-saga/effects";
import createAccount from "./createAccount";
import auth from "./login";
import {
  checkPhoneNumber,
  forgetPassword,
  resetPassword,
  verifyAccount,
} from "./manageAccount";

import {
  addCategory,
  fetchCategory,
  updateCategory,
  deleteCategory,
} from "./category";
import {
  addSubCategory,
  deleteSubCategory,
  fetchSubCategory,
  fetchSubCategoryById,
  updateSubCategory,
} from "./subCategory";
import {
  addIntervention,
  fetchIntervention,
  fetchInterventionBy,
  deleteIntervention,
  addVideo,
  fetchActivity,
  fetchActivityByseason,
  updateIntervetion,
} from "./post";
import {
  addLanguage,
  fetchLanguage,
  updateLanguage,
  deleteLanguage,
} from "./language";
import {
  addResource,
  fetchResource,
  updateResource,
  deleteResource,
} from "./resource";
import { fetchSeason } from "./season";
import { addContac } from "./contact";
import specialUserAccount from "./specialUserAccount";
import { createNews, deletedNews, fetchNews, updatedNews } from "./news";
import {
  createPartner,
  deletedPartner,
  fetchPartner,
  updatedPartner,
} from "./partner";
import {
  createChannel,
  deletedChannel,
  fetchChannel,
  updatedChannel,
} from "./channel";
import { deleteFeedback, fetchFeedback } from "./feedback";
import { fetchTotalUser } from "./totalUser";

export default function* rootSaga() {
  yield all([
    auth(),
    createAccount(),
    specialUserAccount(),
    verifyAccount(),
    forgetPassword(),
    checkPhoneNumber(),
    resetPassword(),
    addCategory(),
    fetchCategory(),
    fetchSubCategoryById(),
    updateCategory(),
    deleteCategory(),
    addSubCategory(),
    fetchSubCategory(),
    updateSubCategory(),
    deleteSubCategory(),
    addResource(),
    fetchResource(),
    updateResource(),
    deleteResource(),
    addIntervention(),
    fetchIntervention(),
    updateIntervetion(),
    fetchInterventionBy(),
    fetchActivity(),
    fetchActivityByseason(),
    deleteIntervention(),
    addLanguage(),
    fetchLanguage(),
    updateLanguage(),
    deleteLanguage(),
    addVideo(),
    fetchSeason(),
    addContac(),
    createNews(),
    fetchNews(),
    deletedNews(),
    updatedNews(),
    createPartner(),
    fetchPartner(),
    updatedPartner(),
    deletedPartner(),
    createChannel(),
    fetchChannel(),
    updatedChannel(),
    deletedChannel(),
    fetchFeedback(),
    deleteFeedback(),
    fetchTotalUser(),
  ]);
}
