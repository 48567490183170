import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { baseUrl } from "../../utils/index";
import { getTotalUserFaild, getTotalUserSuccess } from "../actions/users";

function getTotalUserApi() {
    return axios.get(`${baseUrl}/user/totalUsers`)
}
function* getUser() {
    try {
        const getResponse = yield call(getTotalUserApi);
        if (getResponse.data.error)
            yield put(getTotalUserFaild(getResponse.data.msg));
        else yield put(getTotalUserSuccess(getResponse.data));
    } catch (error) {
        yield put(getTotalUserFaild(error));
    }
}


function* fetchTotalUser() {
    yield takeLatest("GET_TOTALUSER_REQUESTED", getUser);
}

export { fetchTotalUser };
