import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { baseUrl } from '../../utils/index';
import {
  addSubCategorySuccess,
  addSubCategoryFaild,
  getSubCategorySuccess,
  getSubCategoryFaild,
  updateSubCategoryFaild,
  updateSubCategorySuccess,
  deleteSubCategoryFaild,
  deleteSubCategorySuccess,
  getSubCategoryByIdSuccess,
  getSubCategoryByIdFaild,
} from "../actions/sub_category";
//add sub category to database
function addSubCategoryApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.post(`${baseUrl}/sub-category/add-new-sub-category`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addSubCate({ payload: { values } }) {
  try {
    const subCategoryResponse = yield call(addSubCategoryApi, { values });
    if (subCategoryResponse.data.error)
      yield put(addSubCategoryFaild(subCategoryResponse.data.msg));
    else yield put(addSubCategorySuccess(subCategoryResponse.data.msg));
  } catch (error) {
    yield put(addSubCategoryFaild(error));
  }
}
//fetch Sub category from database
function getSubCategoryApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/sub-category`, {
    headers: { Authorization: `Bearer ${token}`},
  });
}
function* getSubCate(token) {
  try {
    const getResponse = yield call(getSubCategoryApi);
    if (getResponse.data.error)
      yield put(getSubCategoryFaild(getResponse.data.msg));
    else yield put(getSubCategorySuccess(getResponse.data.result));
  } catch (error) {
    yield put(getSubCategoryFaild(error));
  }
}
function getSubCategoryByIdApi({ id }) {
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null;
  
  return axios.get(`${baseUrl}/sub-category/by-category-id/${id}`,{headers: {Authorization: `Bearer ${token}`}});
}
function* getSubCategoryById({ payload: { id } }) {
  try {
    const getResponse = yield call(getSubCategoryByIdApi, { id });
    if (getResponse.data.error)
      yield put(getSubCategoryByIdFaild(getResponse.data.msg));
    else yield put(getSubCategoryByIdSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getSubCategoryByIdFaild(error));
  }
}
function updateSubCategoryApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/sub-category/update-sub-category/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateSub({ payload: { values, id } }) {
  try {
    const response = yield call(updateSubCategoryApi, { values, id });
    if (response.data.error) yield put(updateSubCategoryFaild(response.data.msg));
     else yield put(updateSubCategorySuccess(response.data.msg));
  } catch (error) {
    yield put(updateSubCategoryFaild(error));
  }
}

function delSubCategoryApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/sub-category/delete-sub-category/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteSubCate({ payload: { id } }) {
  try {
    const response = yield call(delSubCategoryApi, { id });
    if (response.data.error)
      yield put(deleteSubCategoryFaild(response.data.message));
    else yield put(deleteSubCategorySuccess(response.data.message));
  } catch (error) {
    yield put(deleteSubCategoryFaild(error));
  }
}
function* addSubCategory() {
  yield takeLatest("ADD_SUB_CATEGORY_REQUESTED", addSubCate);
}
function* fetchSubCategory() {
  yield takeLatest("GET_SUB_CATEGORY_REQUESTED", getSubCate);
}
function* fetchSubCategoryById() {
  yield takeLatest("GET_SUB_CATEGORY_BYID_REQUESTED", getSubCategoryById);
}
function* updateSubCategory() {
  yield takeLatest("UPDATE_SUB_CATEGORY_REQUESTED", updateSub);
}
function* deleteSubCategory() {
  yield takeLatest("DELETE_SUB_CATEGORY_REQUESTED", deleteSubCate);
}
export {
  addSubCategory,
  fetchSubCategory,
  fetchSubCategoryById,
  updateSubCategory,
  deleteSubCategory,
};
