import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addCategorySuccess,
  addCategoryFaild,
  getCategoryFaild,
  getCategorySuccess,
  updateCategoryFaild,
  updateCategorySuccess,
  deleteCategoryFaild,
  deleteCategorySuccess,
} from "../actions/category";
import { baseUrl } from "../../utils/index";
//add category to database
function addCategoryApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.post(`${baseUrl}/categories/add-new-category`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addCate({ payload: { values } }) {
  try {
    const categoryResponse = yield call(addCategoryApi, { values });
    if (categoryResponse.data.error)
      yield put(addCategoryFaild(categoryResponse.data.msg));
    else yield put(addCategorySuccess(categoryResponse.data.msg));
  } catch (error) {
    yield put(addCategoryFaild(error));
  }
}
//fetch category from database
function getCategoryApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/categories`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getCate(token) {
  try {
    const getResponse = yield call(getCategoryApi);
    if (getResponse.data.error)
      yield put(getCategoryFaild(getResponse.data.msg));
    else yield put(getCategorySuccess(getResponse.data.result));
  } catch (error) {
    yield put(getCategoryFaild(error));
  }
}
function updateCategoryApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/categories/update-category/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateCate({ payload: { values, id } }) {
  try {
    const response = yield call(updateCategoryApi, { values, id });
    if (response.data.error) yield put(updateCategoryFaild(response.data.msg));
    else yield put(updateCategorySuccess(response.data.msg));
  } catch (error) {
    yield put(updateCategoryFaild(error));
  }
}

function delCategoryApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/categories/delete-category/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteCate({ payload: { id } }) {
  try {
    const response = yield call(delCategoryApi, { id });
    if (response.data.error) {
      yield put(deleteCategoryFaild(response.data.msg));
    } else {
      yield put(deleteCategorySuccess(response.data.msg));
    }
  } catch (error) {
    yield put(deleteCategoryFaild(error));
  }
}
function* addCategory() {
  yield takeLatest("ADD_CATEGORY_REQUESTED", addCate);
}
function* fetchCategory() {
  yield takeLatest("GET_CATEGORY_REQUESTED", getCate);
}
function* updateCategory() {
  yield takeLatest("UPDATE_CATEGORY_REQUESTED", updateCate);
}
function* deleteCategory() {
  yield takeLatest("DELETE_CATEGORY_REQUESTED", deleteCate);
}
export { addCategory, fetchCategory, updateCategory, deleteCategory };
