import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addNewsFaild,
  addNewsSuccess,
  deleteNewsFaild,
  deleteNewsSuccess,
  getNewsFaild,
  getNewsSuccess,
  updateNewsFaild,
  updateNewsSuccess,
} from "../actions/news";
import { baseUrl } from "../../utils/index";

//add category to database
function addNewsApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  const formData = new FormData();
  formData.append("channel_id", values?.channel_id);
  formData.append("title", values?.title);
  formData.append("newscontent", values?.newscontent);
  formData.append("file", values?.isFile ? values?.files : values?.imageurl);
  formData.append("isFile", values?.isFile);

  return axios.post(`${baseUrl}/news/`, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addNews({ payload: { values } }) {
  try {
    const response = yield call(addNewsApi, { values });
    if (response.data.error) yield put(addNewsFaild(response.data.msg));
    else yield put(addNewsSuccess(response.data.msg));
  } catch (error) {
    yield put(addNewsFaild(error?.message));
  }
}
//fetch news from database
function getNewsApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;

  return axios.get(`${baseUrl}/news/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getNews() {
  try {
    const response = yield call(getNewsApi);
    if (response.data.error) yield put(getNewsFaild(response?.data?.msg));
    else yield put(getNewsSuccess(response?.data?.data));
  } catch (error) {
    yield put(getNewsFaild(error?.message));
  }
}
function updateNewsApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;

  return axios.put(`${baseUrl}/news/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateNews({ payload: { values, id } }) {
  try {
    const response = yield call(updateNewsApi, { values, id });
    if (response.data.error) yield put(updateNewsFaild(response.data.msg));
    else yield put(updateNewsSuccess(response.data.msg));
  } catch (error) {
    yield put(updateNewsFaild(error));
  }
}

function delNewsApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/news/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteNews({ payload: { id } }) {
  try {
    const response = yield call(delNewsApi, { id });
    if (response.data.error) {
      yield put(deleteNewsFaild(response.data.msg));
    } else {
      yield put(deleteNewsSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(deleteNewsFaild(error));
  }
}
function* createNews() {
  yield takeLatest("ADD_NEWS_REQUESTED", addNews);
}
function* fetchNews() {
  yield takeLatest("GET_NEWS_REQUESTED", getNews);
}
function* updatedNews() {
  yield takeLatest("UPDATE_NEWS_REQUESTED", updateNews);
}
function* deletedNews() {
  yield takeLatest("DELETE_NEWS_REQUESTED", deleteNews);
}
export { createNews, fetchNews, updatedNews, deletedNews };
