import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addInterventionSuccess,
  addInterventionFaild,
  getInterventionSuccess,
  getInterventionFaild,
  getInterventionByFaild,
  getInterventionBySuccess,
  getActivityFaild,
  getActivitySuccess,
  getActivityByseasonFaild,
  getActivityByseasonSuccess,
  updateInterventionSuccess,
  updateInterventionFaild,
  deleteInterventionSuccess,
  deleteInterventionFaild,
  addVideoFaild,
  addVideoSuccess,
} from "../actions/post";
import { baseUrl } from "../../utils/index";
//add category to database
function addInterventionApi({ data }) {
  //  ${baseUrl}/posts/post
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  if (data.isFile) {
    const formData = new FormData();
    formData.append("sub_category_id", data?.sub_category_id);
    formData.append("activity", data?.activity);
    formData.append("activity_am", data?.activity_am);
    formData.append("activity_or", data?.activity_or);
    formData.append("activity_kf", data?.activity_kf);
    formData.append("activity_sd", data?.activity_sd);
    formData.append("intervention", data?.intervention);
    formData.append("intervention_am", data?.intervention_am);
    formData.append("intervention_or", data?.intervention_or);
    formData.append("intervention_kf", data?.intervention_kf);
    formData.append("intervention_sd", data?.intervention_sd);
    formData.append("image", data?.imgUrl);
    formData.append("month_id", data?.month_id);
    formData.append("resource_id", data?.resource_id);

    return axios.post(`${baseUrl}/posts/post`, formData, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return axios.post(`${baseUrl}/posts/post-url`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
}
function* addInter({ payload: { data } }) {
  try {
    const contentResponse = yield call(addInterventionApi, { data });
    if (contentResponse.data.error)
      yield put(addInterventionFaild(contentResponse.data.msg));
    else yield put(addInterventionSuccess(contentResponse.data.msg));
  } catch (error) {
    yield put(addInterventionFaild(error));
  }
}
//fetch category from database
function getInterventionApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/posts`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getInter(fetchIntervention) {
  try {
    const getResponse = yield call(getInterventionApi);
    if (getResponse.data.error)
      yield put(getInterventionFaild(getResponse.data.msg));
    else yield put(getInterventionSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getInterventionFaild(error));
  }
}
function getActivityApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/posts/by-sub-category/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getActivity({ payload: { id } }) {
  try {
    const getResponse = yield call(getActivityApi, { id });
    if (getResponse.data.error)
      yield put(getActivityFaild(getResponse.data.msg));
    else yield put(getActivitySuccess(getResponse.data.result));
  } catch (error) {
    yield put(getActivityFaild(error));
  }
}
function getActivityByseasonApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/posts/by-season/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getActivityByseason({ payload: { id } }) {
  try {
    const getResponse = yield call(getActivityByseasonApi, { id });
    if (getResponse.data.error)
      yield put(getActivityByseasonFaild(getResponse.data.msg));
    else yield put(getActivityByseasonSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getActivityByseasonFaild(error));
  }
}

function getInterventionByApi({ passedId }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/posts/${passedId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getInterventionBy({ payload: { passedId } }) {
  try {
    const getResponse = yield call(getInterventionByApi, { passedId });
    if (getResponse.data.error)
      yield put(getInterventionByFaild(getResponse.data.msg));
    else yield put(getInterventionBySuccess(getResponse.data.result));
  } catch (error) {
    yield put(getInterventionByFaild(error));
  }
}
function updateInterventionApi({ data, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/posts/update/${id}`, data, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateInter({ payload: { data, id } }) {
  try {
    const getResponse = yield call(updateInterventionApi, { data, id });
    if (getResponse.data.error)
      yield put(updateInterventionFaild(getResponse.data.msg));
    else yield put(updateInterventionSuccess(getResponse.data.msg));
  } catch (error) {
    yield put(updateInterventionFaild(error));
  }
}
function delInterventionApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/posts/delete/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteInter({ payload: { id } }) {
  try {
    const response = yield call(delInterventionApi, { id });
    if (response.data.error)
      yield put(deleteInterventionFaild(response.data.msg));
    else yield put(deleteInterventionSuccess(response.data.msg));
  } catch (error) {
    yield put(deleteInterventionFaild(error));
  }
}
function addVideoApi({ data }) {
  //  ${baseUrl}/posts/post
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  // if (data.isFile) {
  const formData = new FormData();
  formData.append("intervention_id", data?.intervention_id);
  formData.append("video_file", data?.video_file);
  formData.append("video_file_am", data?.video_file_am);
  formData.append("video_file_or", data?.video_file_or);
  formData.append("video_file_kf", data?.video_file_kf);
  formData.append("video_file_sd", data?.video_file_sd);
  return axios.post(`${baseUrl}/posts/post-video`, formData, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addVid({ payload: { data } }) {
  try {
    const videoResponse = yield call(addVideoApi, { data });
    if (videoResponse.data.error)
      yield put(addVideoFaild(videoResponse.data.msg));
    else yield put(addVideoSuccess(videoResponse.data.msg));
  } catch (error) {
    yield put(addVideoFaild(error));
  }
}
function* addIntervention() {
  yield takeLatest("ADD_INTERVENTION_REQUESTED", addInter);
}
function* fetchIntervention() {
  yield takeLatest("GET_INTERVENTION_REQUESTED", getInter);
}
function* fetchInterventionBy() {
  yield takeLatest("GET_INTERVENTION_BY_REQUESTED", getInterventionBy);
}
function* fetchActivity() {
  yield takeLatest("GET_ACTIVITY_REQUESTED", getActivity);
}
function* fetchActivityByseason() {
  yield takeLatest("GET_ACTIVITY_BY_SEASON_REQUESTED", getActivityByseason);
}
function* updateIntervetion() {
  yield takeLatest("UPDATE_INTERVENTION_REQUESTED", updateInter);
}
function* deleteIntervention() {
  yield takeLatest("DELETE_INTERVENTION_REQUESTED", deleteInter);
}
function* addVideo() {
  yield takeLatest("ADD_VIDEO_REQUESTED", addVid);
}
export {
  addIntervention,
  fetchIntervention,
  fetchInterventionBy,
  fetchActivity,
  fetchActivityByseason,
  updateIntervetion,
  deleteIntervention,
  addVideo,
};
