import * as type from "../types";
export const addSubCategoryRequested = (user) => ({
  type: type.ADD_SUB_CATEGORY_REQUESTED,
  payload: user,
});
export const addSubCategorySuccess = (u) => ({
  type: type.ADD_SUB_CATEGORY_SUCCESS,
  payload: u,
});
export const addSubCategoryFaild = (errorMsg) => ({
  type: type.ADD_SUB_CATEGORY_FAILD,
  payload: errorMsg,
});
export const getSubCategoryRequested = () => ({
  type: type.GET_SUB_CATEGORY_REQUESTED,
});
export const getSubCategorySuccess = (subCategory) => ({
  type: type.GET_SUB_CATEGORY_SUCCESS,
  payload: subCategory,
});
export const getSubCategoryFaild = (errorMsg) => ({
  type: type.GET_SUB_CATEGORY_FAILD,
  error: errorMsg,
});
export const updateSubCategoryRequested = (updateSubCategory) => ({
  type: type.UPDATE_SUB_CATEGORY_REQUESTED,
  payload: updateSubCategory,
});
export const updateSubCategorySuccess = (update) => ({
  type: type.UPDATE_SUB_CATEGORY_SUCCESS,
  payload: update,
});
export const updateSubCategoryFaild = (errorMsg) => ({
  type: type.UPDATE_SUB_CATEGORY_FAILD,
  payload: errorMsg,
});

export const deleteSubCategoryRequested = (delSubCategory) => ({
  type: type.DELETE_SUB_CATEGORY_REQUESTED,
  payload: delSubCategory,
});
export const deleteSubCategorySuccess = (delSubC) => ({
  type: type.DELETE_SUB_CATEGORY_SUCCESS,
  payload: delSubC,
});
export const deleteSubCategoryFaild = (errorMsg) => ({
  type: type.DELETE_SUB_CATEGORY_FAILD,
  payload: errorMsg,
});

// get sub categories by id
export const getSubCategoryByIdRequested = (getSub) => ({
  type: type.GET_SUB_CATEGORY_BYID_REQUESTED,
  payload: getSub,
});
export const getSubCategoryByIdSuccess = (get) => ({
  type: type.GET_SUB_CATEGORY_BYID_SUCCESS,
  payload: get,
});
export const getSubCategoryByIdFaild = (errorMsg) => ({
  type: type.GET_SUB_CATEGORY_BYID_FAILD,
  payload: errorMsg,
});

