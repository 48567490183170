import * as type from "../types";
export const addInterventionRequested = (content) => ({
  type: type.ADD_INTERVENTION_REQUESTED,
  payload: content,
});
export const addInterventionSuccess = (u) => ({
  type: type.ADD_INTERVENTION_SUCCESS,
  payload: u,
});
export const addInterventionFaild = (errorMsg) => ({
  type: type.ADD_INTERVENTION_FAILD,
  payload: errorMsg,
});
export const addVideoFaild = (errorMsg) => ({
  type: type.ADD_VIDEO_FAILD,
  payload: errorMsg,
});
export const addVideoRequested = (content) => ({
  type: type.ADD_VIDEO_REQUESTED,
  payload: content,
});
export const addVideoSuccess = (u) => ({
  type: type.ADD_VIDEO_SUCCESS,
  payload: u,
});

export const getInterventionRequested = () => ({
  type: type.GET_INTERVENTION_REQUESTED,
});
export const getInterventionSuccess = (getC) => ({
  type: type.GET_INTERVENTION_SUCCESS,
  payload: getC,
});
export const getInterventionFaild = (errorMsg) => ({
  type: type.GET_INTERVENTION_FAILD,
  error: errorMsg,
});
export const deleteInterventionRequested = (delCont) => ({
  type: type.DELETE_INTERVENTION_REQUESTED,
  payload: delCont,
});
export const deleteInterventionSuccess = (delC) => ({
  type: type.DELETE_INTERVENTION_SUCCESS,
  payload: delC,
});
export const deleteInterventionFaild = (errorMsg) => ({
  type: type.DELETE_INTERVENTION_FAILD,
  payload: errorMsg,
});
export const updateInterventionRequested = (updateContent) => ({
  type: type.UPDATE_INTERVENTION_REQUESTED,
  payload: updateContent,
});
export const updateInterventionSuccess = (update) => ({
  type: type.UPDATE_INTERVENTION_SUCCESS,
  payload: update,
});
export const updateInterventionFaild = (errorMsg) => ({
  type: type.UPDATE_INTERVENTION_FAILD,
  payload: errorMsg,
});
// get activity by sub category
export const getActivityRequested = (getActivity) => ({
  type: type.GET_ACTIVITY_REQUESTED,
  payload: getActivity,
});
export const getActivitySuccess = (get) => ({
  type: type.GET_ACTIVITY_SUCCESS,
  payload: get,
});
export const getActivityFaild = (errorMsg) => ({
  type: type.GET_ACTIVITY_FAILD,
  payload: errorMsg,
});
// get activity by season
export const getActivityByseasonRequested = (getActivity) => ({
  type: type.GET_ACTIVITY_BY_SEASON_REQUESTED,
  payload: getActivity,
});
export const getActivityByseasonSuccess = (get) => ({
  type: type.GET_ACTIVITY_BY_SEASON_SUCCESS,
  payload: get,
});
export const getActivityByseasonFaild = (errorMsg) => ({
  type: type.GET_ACTIVITY_BY_SEASON_FAILD,
  payload: errorMsg,
});
// for specific intervention by id
export const getInterventionByRequested = (getContent) => ({
  type: type.GET_INTERVENTION_BY_REQUESTED,
  payload: getContent,
});
export const getInterventionBySuccess = (get) => ({
  type: type.GET_INTERVENTION_BY_SUCCESS,
  payload: get,
});
export const getInterventionByFaild = (errorMsg) => ({
  type: type.GET_INTERVENTION_BY_FAILD,
  payload: errorMsg,
});
