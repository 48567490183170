import * as type from "../types";
export const addCategoryRequested = (user) => ({
  type: type.ADD_CATEGORY_REQUESTED,
  payload: user,
});
export const addCategorySuccess = (u) => ({
  type: type.ADD_CATEGORY_SUCCESS,
  payload: u,
});
export const addCategoryFaild = (errorMsg) => ({
  type: type.ADD_CATEGORY_FAILD,
  payload: errorMsg,
});
export const getCategoryRequested = () => ({
  type: type.GET_CATEGORY_REQUESTED,
});
export const getCategorySuccess = (cate) => ({
  type: type.GET_CATEGORY_SUCCESS,
  payload: cate,
});
export const getCategoryFaild = (errorMsg) => ({
  type: type.GET_CATEGORY_FAILD,
  error: errorMsg,
});
export const deleteCategoryRequested = (delCategory) => ({
  type: type.DELETE_CATEGORY_REQUESTED,
  payload: delCategory,
});
export const deleteCategorySuccess = (delC) => ({
  type: type.DELETE_CATEGORY_SUCCESS,
  payload: delC,
});
export const deleteCategoryFaild = (errorMsg) => ({
  type: type.DELETE_CATEGORY_FAILD,
  payload: errorMsg,
});

export const updateCategoryRequested = (updateCategory) => ({
  type: type.UPDATE_CATEGORY_REQUESTED,
  payload: updateCategory,
});
export const updateCategorySuccess = (update) => ({
  type: type.UPDATE_CATEGORY_SUCCESS,
  payload: update,
});
export const updateCategoryFaild = (errorMsg) => ({
  type: type.UPDATE_CATEGORY_FAILD,
  payload: errorMsg,
});

