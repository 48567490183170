import { takeLatest, put, call } from "redux-saga/effects";
import { authenticationFaild, authenticationSuccess } from "../actions/users";
import { baseUrl } from "../../utils/index";
import axios from "axios";
function getApi({ values }) {
  return axios.post(`${baseUrl}/user/login`, values);
}
function* login({ payload: { values } }) {
  try {
    const newAccount = yield call(getApi, { values });
    if (newAccount.data.error)
      yield put(authenticationFaild(newAccount.data.msg));
    else {
      // save user data to local storage
      localStorage.setItem("userInfo", JSON.stringify(newAccount.data));
      yield put(authenticationSuccess(newAccount.data));
    }
  } catch (error) {
    yield put(authenticationFaild(error));
  }
}
function* authentication() {
  yield takeLatest("AUTHENTICATION_REQUESTED", login);
}
export default authentication;
