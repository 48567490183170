import * as type from "../types";

export const getFeedbackRequested = () => ({
  type: type.GET_FEEDBACK_REQUESTED,
});
export const getFeedbackSuccess = (feedback) => ({
  type: type.GET_FEEDBACK_SUCCESS,
  payload: feedback,
});
export const getFeedbackFaild = (errorMsg) => ({
  type: type.GET_FEEDBACK_FAILD,
  error: errorMsg,
});
export const deleteFeedbackRequested = (delFeedback) => ({
  type: type.DELETE_FEEDBACK_REQUESTED,
  payload: delFeedback,
});
export const deleteFeedbackSuccess = (delF) => ({
  type: type.DELETE_FEEDBACK_SUCCESS,
  payload: delF,
});
export const deleteFeedbackFaild = (errorMsg) => ({
  type: type.DELETE_FEEDBACK_FAILD,
  payload: errorMsg,
});
