import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import { baseUrl} from '../../utils/index';
import { addResourceSuccess,
         addResourceFaild,
         getResourceSuccess,
         getResourceFaild,
        updateResourceFaild,
        updateResourceSuccess,
         deleteResourceFaild,
         deleteResourceSuccess, } from "../actions/resource";

//add resource to database
function addResourceApi({ values }){
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null;
  return axios.post(`${baseUrl}/resources/add-new-resource`, values,{headers: {Authorization: `Bearer ${token}`}
});
}
function* addResources({ payload: { values } }) {
  try {
    const resourceResponse = yield call(addResourceApi, { values });
    if (resourceResponse.data.error)
      yield put(addResourceFaild(resourceResponse.data.msg));
    else yield put(addResourceSuccess(resourceResponse.data.msg));
  } catch (error) {
    yield put(addResourceFaild(error));
  }
} 
//fetch Sub category from database
function getResourceApi(){ 
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null; 
  return axios.get(`${baseUrl}/resources`,{headers: {Authorization: `Bearer ${token}`}}); 
}
function* getResource( token  ) { 
  try {
    const getResponse = yield call(getResourceApi);
    if(getResponse.data.error)
      yield put(getResourceFaild(getResponse.data.msg));
    else yield put(getResourceSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getResourceFaild(error));
  }
}
function delResourceApi({id}){
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null;
  return  axios.delete(`${baseUrl}/resources/delete-resource/${id}`,{headers: {Authorization: `Bearer ${token}`}});
}
function updateResourceApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/resources/update-resource/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateResou({ payload: { values, id } }) {
  try {
    const response = yield call(updateResourceApi, { values, id });
    if (response.data.error) yield put(updateResourceFaild(response.data.msg));
    else yield put(updateResourceSuccess(response.data.msg));
  } catch (error) {
    yield put(updateResourceFaild(error));
  }
}
function* deleteResources({ payload: { id } }){
  try{
      const response=yield call(delResourceApi,{id});
      if(response.data.error)
      yield put(deleteResourceFaild(response.data.msg));
     else
      yield put(deleteResourceSuccess(response.data.msg));
  }
  catch(error){
    yield put(deleteResourceFaild(error));
  }
}
function* addResource(){
  yield takeLatest("ADD_RESOURCE_REQUESTED", addResources);
}
function* fetchResource(){
  yield takeLatest("GET_RESOURCE_REQUESTED", getResource);
}
function* updateResource(){
  yield takeLatest("UPDATE_RESOURCE_REQUESTED", updateResou);
}
function* deleteResource(){
  yield takeLatest('DELETE_RESOURCE_REQUESTED',deleteResources);
}
export  {addResource,fetchResource,updateResource, deleteResource};
