import * as type from "../types";
export const authenticationRequested = (user) => ({
  type: type.AUTHENTICATION_REQUESTED,
  payload: user,
});
export const authenticationSuccess = (u) => ({
  type: type.AUTHENTICATION_SUCCESS,
  payload: u,
});
export const authenticationFaild = (errorMsg) => ({
  type: type.AUTHENTICATION_FAILD,
  payload: errorMsg,
});
export const createAccountRequested = (user) => ({
  type: type.CREATE_ACCOUNT_REQUESTED,
  payload: user,
});
export const createAccountSuccess = (u) => ({
  type: type.CREATE_ACCOUNT_SUCCESS,
  payload: u,
});
export const createAccountFaild = (errorMsg) => ({
  type: type.CREATE_ACCOUNT_FAILD,
  payload: errorMsg,
});
export const specialUserAccountRequested = (user) => ({
  type: type.SPECIAL_USER_ACCOUNT_REQUESTED,
  payload: user,
});
export const specialUserAccountSuccess = (u) => ({
  type: type.SPECIAL_USER_ACCOUNT_SUCCESS,
  payload: u,
});
export const specialUserAccountFaild = (errorMsg) => ({
  type: type.SPECIAL_USER_ACCOUNT_FAILD,
  payload: errorMsg,
});
export const forgetPasswordRequested = (forgetInfo) => ({
  type: type.FORGET_PASSWORD_REQUESTED,
  payload: forgetInfo,
});
export const forgetPasswordSuccess = (info) => ({
  type: type.FORGET_PASSWORD_SUCCESS,
  payload: info,
});
export const forgetPasswordFaild = (errorMsg) => ({
  type: type.FORGET_PASSWORD_FAILD,
  payload: errorMsg,
});
export const checkPhoneNumberRequested = (phone) => ({
  type: type.CHECK_PHONENUMBER_REQUESTED,
  payload: phone,
});
export const checkPhoneNumberSuccess = (info) => ({
  type: type.CHECK_PHONENUMBER_SUCCESS,
  payload: info,
});
export const checkPhoneNumberFaild = (errorMsg) => ({
  type: type.CHECK_PHONENUMBER_FAILD,
  payload: errorMsg,
});


export const resetPasswordRequested = (resetInfo) => ({
  type: type.RESET_PASSWORD_REQUESTED,
  payload: resetInfo,
});
export const resetPasswordSuccess = (info) => ({
  type: type.RESET_PASSWORD_SUCCESS,
  payload: info,
});
export const resetPasswordFaild = (errorMsg) => ({
  type: type.RESET_PASSWORD_FAILD,
  payload: errorMsg,
});
export const verifyAccountRequested = (resetInfo) => ({
  type: type.VERIFY_ACCOUNT_REQUESTED,
  payload: resetInfo,
});
export const verifyAccountSuccess = (info) => ({
  type: type.VERIFY_ACCOUNT_SUCCESS,
  payload: info,
});
export const verifyAccountFaild = (errorMsg) => ({
  type: type.VERIFY_ACCOUNT_FAILD,
  payload: errorMsg,
});

export const getTotalUserRequested = () => ({
  type: type.GET_TOTALUSER_REQUESTED,
});
export const getTotalUserSuccess = (total) => ({
  type: type.GET_TOTALUSER_SUCCESS,
  payload: total,
});
export const getTotalUserFaild = (errorMsg) => ({
  type: type.GET_TOTALUSER_FAILD,
  error: errorMsg,
});