import * as type from "../types";
export const addLanguageRequested = (language) => ({
  type: type.ADD_LANGUAGE_REQUESTED,
  payload: language,
});
export const addLanguageSuccess = (u) => ({
  type: type.ADD_LANGUAGE_SUCCESS,
  payload: u,
});
export const addLanguageFaild = (errorMsg) => ({
  type: type.ADD_LANGUAGE_FAILD,
  payload: errorMsg,
});
export const getLanguageRequested = () => ({
  type: type.GET_LANGUAGE_REQUESTED,
});
export const getLanguageSuccess = (lang) => ({
  type: type.GET_LANGUAGE_SUCCESS,
  payload: lang,
});
export const getLanguageFaild = (errorMsg) => ({
  type: type.GET_LANGUAGE_FAILD,
  error: errorMsg,
});
export const deleteLanguageRequested = (delLanguage) => ({
  type: type.DELETE_LANGUAGE_REQUESTED,
  payload: delLanguage,
});
export const deleteLanguageSuccess = (dell) => ({
  type: type.DELETE_LANGUAGE_SUCCESS,
  payload: dell,
});
export const deleteLanguageFaild = (errorMsg) => ({
  type: type.DELETE_LANGUAGE_FAILD,
  payload: errorMsg,
});
export const updateLanguageRequested = (updateLanguage) => ({
  type: type.UPDATE_LANGUAGE_REQUESTED,
  payload: updateLanguage,
});
export const updateLanguageSuccess = (update) => ({
  type: type.UPDATE_LANGUAGE_SUCCESS,
  payload: update,
});
export const updateLanguageFaild = (errorMsg) => ({
  type: type.UPDATE_LANGUAGE_FAILD,
  payload: errorMsg,
});
