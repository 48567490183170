import * as type from "../types";
export const addNewsRequested = (user) => ({
  type: type.ADD_NEWS_REQUESTED,
  payload: user,
});
export const addNewsSuccess = (u) => ({
  type: type.ADD_NEWS_SUCCESS,
  payload: u,
});
export const addNewsFaild = (errorMsg) => ({
  type: type.ADD_NEWS_FAILD,
  payload: errorMsg,
});
export const getNewsRequested = () => ({
  type: type.GET_NEWS_REQUESTED,
});
export const getNewsSuccess = (news) => ({
  type: type.GET_NEWS_SUCCESS,
  payload: news,
});
export const getNewsFaild = (errorMsg) => ({
  type: type.GET_NEWS_FAILD,
  error: errorMsg,
});

export const updateNewsRequested = (update) => ({
  type: type.UPDATE_NEWS_REQUESTED,
  payload: update,
});
export const updateNewsSuccess = (update) => ({
  type: type.UPDATE_NEWS_SUCCESS,
  payload: update,
});
export const updateNewsFaild = (errorMsg) => ({
  type: type.UPDATE_NEWS_FAILD,
  payload: errorMsg,
});
export const deleteNewsRequested = (del) => ({
  type: type.DELETE_NEWS_REQUESTED,
  payload: del,
});
export const deleteNewsSuccess = (del) => ({
  type: type.DELETE_NEWS_SUCCESS,
  payload: del,
});
export const deleteNewsFaild = (errorMsg) => ({
  type: type.DELETE_NEWS_FAILD,
  payload: errorMsg,
});
