import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  deleteFeedbackFaild,
  deleteFeedbackSuccess,
  getFeedbackFaild,
  getFeedbackSuccess,
} from "../actions/feedback";
import { baseUrl } from "../../utils/index";

//fetch feedback from database
function getFeedbackApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/feedbacks/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getFeedB(token) {
  try {
    const getResponse = yield call(getFeedbackApi);
    if (getResponse.data.error)
      yield put(getFeedbackFaild(getResponse.data.msg));
    else yield put(getFeedbackSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getFeedbackFaild(error));
  }
}

function delFeedbackApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/feedbacks/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteFeedB({ payload: { id } }) {
  try {
    const response = yield call(delFeedbackApi, { id });
    if (response.data.error) {
      yield put(deleteFeedbackFaild(response.data.msg));
    } else {
      yield put(deleteFeedbackSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(deleteFeedbackFaild(error));
  }
}

function* fetchFeedback() {
  yield takeLatest("GET_FEEDBACK_REQUESTED", getFeedB);
}

function* deleteFeedback() {
  yield takeLatest("DELETE_FEEDBACK_REQUESTED", deleteFeedB);
}
export { fetchFeedback, deleteFeedback };
