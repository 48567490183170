import * as type from "../types";
//get user from local storage and place into store
const userFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;
const initialState = {
  users: userFromStorage,
  categories: [],
  subCategories: [],
  resources: [],
  contents: [],
  contentById: [],
  languages: [],
  news: [],
  partner: [],
  channel: [],
  feedback: [],
  loading: false,
  error: false,
  message: "",
  errorMsg: true,
  deleteUserName: "",
  editUser: "",
  months: [],
  totalusers: [],
};
export default function employees(state = initialState, action) {
  switch (action.type) {
    case type.AUTHENTICATION_REQUESTED:
    case type.CREATE_ACCOUNT_REQUESTED:
    case type.SPECIAL_USER_ACCOUNT_REQUESTED:
    case type.FORGET_PASSWORD_REQUESTED:
    case type.CHECK_PHONENUMBER_REQUESTED:
    case type.RESET_PASSWORD_REQUESTED:
    case type.VERIFY_ACCOUNT_REQUESTED:
    case type.ADD_CATEGORY_REQUESTED:
    case type.GET_CATEGORY_REQUESTED:
    case type.DELETE_CATEGORY_REQUESTED:
    case type.UPDATE_CATEGORY_REQUESTED:
    case type.GET_NEWS_REQUESTED:
    case type.DELETE_NEWS_REQUESTED:
    case type.UPDATE_NEWS_REQUESTED:
    case type.ADD_SUB_CATEGORY_REQUESTED:
    case type.GET_SUB_CATEGORY_REQUESTED:
    case type.GET_SUB_CATEGORY_BYID_REQUESTED:
    case type.UPDATE_SUB_CATEGORY_REQUESTED:
    case type.DELETE_SUB_CATEGORY_REQUESTED:
    case type.ADD_RESOURCE_REQUESTED:
    case type.GET_RESOURCE_REQUESTED:
    case type.UPDATE_RESOURCE_REQUESTED:
    case type.DELETE_RESOURCE_REQUESTED:
    case type.ADD_INTERVENTION_REQUESTED:
    case type.ADD_VIDEO_REQUESTED:
    case type.GET_INTERVENTION_REQUESTED:
    case type.GET_INTERVENTION_BY_REQUESTED:
    case type.UPDATE_INTERVENTION_REQUESTED:
    case type.GET_ACTIVITY_REQUESTED:
    case type.GET_ACTIVITY_BY_SEASON_REQUESTED:
    case type.DELETE_INTERVENTION_REQUESTED:
    case type.ADD_LANGUAGE_REQUESTED:
    case type.GET_LANGUAGE_REQUESTED:
    case type.UPDATE_LANGUAGE_REQUESTED:
    case type.DELETE_LANGUAGE_REQUESTED:
    case type.GET_SEASON_REQUESTED:
    case type.ADD_CONTACT_REQUESTED:
    case type.ADD_PARTNER_REQUESTED:
    case type.GET_PARTNER_REQUESTED:
    case type.UPDATE_PARTNER_REQUESTED:
    case type.DELETE_PARTNER_REQUESTED:
    case type.ADD_CHANNEL_REQUESTED:
    case type.GET_CHANNEL_REQUESTED:
    case type.UPDATE_CHANNEL_REQUESTED:
    case type.DELETE_CHANNEL_REQUESTED:
    case type.GET_FEEDBACK_REQUESTED:
    case type.DELETE_FEEDBACK_REQUESTED:
    case type.GET_TOTALUSER_REQUESTED:
      return {
        ...state,
        error: false,
        loading: true,
        message: "",
        errorMsg: true,
      };
    case type.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        categories: action.payload,
      };
    case type.GET_TOTALUSER_SUCCESS:
      return {
        ...state,
        loading: false,
        totalusers: action.payload
      };
    case type.GET_NEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        news: action.payload,
      };
    case type.GET_PARTNER_SUCCESS:
      return {
        ...state,
        loading: false,
        partner: action.payload,
      };
    case type.GET_CHANNEL_SUCCESS:
      return {
        ...state,
        loading: false,
        channel: action.payload,
      };
    case type.GET_SUB_CATEGORY_SUCCESS:
    case type.GET_SUB_CATEGORY_BYID_SUCCESS:
      return {
        ...state,
        loading: false,
        subCategories: action.payload,
      };
    case type.GET_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        resources: action.payload,
      };
    case type.GET_LANGUAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        languages: action.payload,
      };
    case type.GET_SEASON_SUCCESS:
      return {
        ...state,
        loading: false,
        months: action.payload,
      };
    case type.GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        feedback: action.payload,
      };
    case type.GET_INTERVENTION_SUCCESS:
    case type.GET_INTERVENTION_BY_SUCCESS:
      return {
        ...state,
        loading: false,
        contents: action.payload,
      };
    case type.GET_ACTIVITY_SUCCESS:
    case type.GET_ACTIVITY_BY_SEASON_SUCCESS:
      return {
        ...state,
        loading: false,
        contentById: action.payload,
      };
    case type.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload.msg,
        users: action.payload,
        errorMsg: false,
      };
    case type.ADD_INTERVENTION_SUCCESS:
    case type.ADD_VIDEO_SUCCESS:
    case type.ADD_CATEGORY_SUCCESS:
    case type.ADD_SUB_CATEGORY_SUCCESS:
    case type.ADD_RESOURCE_SUCCESS:
    case type.CREATE_ACCOUNT_SUCCESS:
    case type.SPECIAL_USER_ACCOUNT_SUCCESS:
    case type.ADD_LANGUAGE_SUCCESS:
    case type.FORGET_PASSWORD_SUCCESS:
    case type.CHECK_PHONENUMBER_SUCCESS:
    case type.RESET_PASSWORD_SUCCESS:
    case type.VERIFY_ACCOUNT_SUCCESS:
    case type.ADD_CONTACT_SUCCESS:
    case type.ADD_NEWS_SUCCESS:
    case type.UPDATE_NEWS_SUCCESS:
    case type.DELETE_NEWS_SUCCESS:
    case type.ADD_PARTNER_SUCCESS:
    case type.UPDATE_PARTNER_SUCCESS:
    case type.DELETE_PARTNER_SUCCESS:
    case type.ADD_CHANNEL_SUCCESS:
    case type.UPDATE_CHANNEL_SUCCESS:
    case type.DELETE_CHANNEL_SUCCESS:
    case type.DELETE_FEEDBACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload,
        errorMsg: false,
      };
    case type.DELETE_INTERVENTION_SUCCESS:
    case type.DELETE_LANGUAGE_SUCCESS:
    case type.DELETE_CATEGORY_SUCCESS:
    case type.DELETE_SUB_CATEGORY_SUCCESS:
    case type.DELETE_RESOURCE_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
        errorMsg: false,
      };
    case type.UPDATE_CATEGORY_SUCCESS:
    case type.UPDATE_SUB_CATEGORY_SUCCESS:
    case type.UPDATE_LANGUAGE_SUCCESS:
    case type.UPDATE_RESOURCE_SUCCESS:
    case type.UPDATE_INTERVENTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        message: action.payload,
        errorMsg: false,
      };
    case type.AUTHENTICATION_FAILD:
    case type.CREATE_ACCOUNT_FAILD:
    case type.SPECIAL_USER_ACCOUNT_FAILD:
    case type.FORGET_PASSWORD_FAILD:
    case type.CHECK_PHONENUMBER_FAILD:
    case type.RESET_PASSWORD_FAILD:
    case type.VERIFY_ACCOUNT_FAILD:
    case type.ADD_CATEGORY_FAILD:
    case type.GET_CATEGORY_FAILD:
    case type.UPDATE_CATEGORY_FAILD:
    case type.DELETE_CATEGORY_FAILD:
    case type.ADD_SUB_CATEGORY_FAILD:
    case type.GET_SUB_CATEGORY_FAILD:
    case type.GET_SUB_CATEGORY_BYID_FAILD:
    case type.UPDATE_SUB_CATEGORY_FAILD:
    case type.DELETE_SUB_CATEGORY_FAILD:
    case type.ADD_RESOURCE_FAILD:
    case type.GET_RESOURCE_FAILD:
    case type.UPDATE_RESOURCE_FAILD:
    case type.DELETE_RESOURCE_FAILD:
    case type.GET_INTERVENTION_FAILD:
    case type.UPDATE_INTERVENTION_FAILD:
    case type.GET_ACTIVITY_FAILD:
    case type.GET_ACTIVITY_BY_SEASON_FAILD:
    case type.GET_INTERVENTION_BY_FAILD:
    case type.ADD_INTERVENTION_FAILD:
    case type.DELETE_INTERVENTION_FAILD:
    case type.ADD_LANGUAGE_FAILD:
    case type.GET_LANGUAGE_FAILD:
    case type.UPDATE_LANGUAGE_FAILD:
    case type.DELETE_LANGUAGE_FAILD:
    case type.ADD_VIDEO_FAILD:
    case type.GET_SEASON_FAILD:
    case type.ADD_CONTACT_FAILD:
    case type.ADD_NEWS_FAILD:
    case type.GET_NEWS_FAILD:
    case type.UPDATE_NEWS_FAILD:
    case type.DELETE_NEWS_FAILD:
    case type.ADD_PARTNER_FAILD:
    case type.GET_PARTNER_FAILD:
    case type.UPDATE_PARTNER_FAILD:
    case type.DELETE_PARTNER_FAILD:
    case type.ADD_CHANNEL_FAILD:
    case type.GET_CHANNEL_FAILD:
    case type.UPDATE_CHANNEL_FAILD:
    case type.DELETE_CHANNEL_FAILD:
    case type.GET_FEEDBACK_FAILD:
    case type.DELETE_FEEDBACK_FAILD:
    case type.GET_TOTALUSER_FAILD:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload,
        errorMsg: true,
      };
    default:
      return state;
  }
}
