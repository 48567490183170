import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addLanguageSuccess,
  addLanguageFaild,
  getLanguageFaild,
  getLanguageSuccess,
  updateLanguageSuccess,
  updateLanguageFaild,
  deleteLanguageFaild,
  deleteLanguageSuccess
} from "../actions/language";

import { baseUrl } from '../../utils/index';
//add language to database
function addLanguageApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.post(`${baseUrl}/languages/add-new-language`,
    values,
    { headers: { Authorization: `Bearer ${token}` } }
  );
}
function* addLang({ payload: { values } }) {
  try {
    const languageResponse = yield call(addLanguageApi, { values });
    if (languageResponse.data.error) {
      yield put(addLanguageFaild(languageResponse.data.msg));
    } else {
      yield put(addLanguageSuccess(languageResponse.data.msg));
    }
  } catch (error) {
    yield put(addLanguageFaild(error));
  }
}
//fetch language from database
function getLanguageApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/languages`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getLang(token) {
  try {
    const getResponse = yield call(getLanguageApi);
    if (getResponse.data.error)
      yield put(getLanguageFaild(getResponse.data.msg));
    else yield put(getLanguageSuccess(getResponse.data.result));
  } catch (error) {
    yield put(getLanguageFaild(error));
  }
}
function delLanguageApi({id}){ 
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null; 
  return  axios.delete(`${baseUrl}/languages/delete-language/${id}`,{headers: {Authorization: `Bearer ${token}`}});
}
function* deleteLang({ payload: { id } }){
  try{
      const response=yield call(delLanguageApi,{id});
      if(response.data.error)
      yield put(deleteLanguageFaild(response.data.message));
     else
      yield put(deleteLanguageSuccess(response.data.message));
  }
  catch(error){
    yield put(deleteLanguageFaild(error));
  }
}

function updateLanguageApi({values,id}){ 
  const token = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo")).token
  : null; 
  return  axios.put(`${baseUrl}/languages/update-language/${id}`,values,{headers: {Authorization: `Bearer ${token}`}});
}
function* updateLang({ payload: { values,id } }){
  try{
      const response=yield call(updateLanguageApi,{values,id});
      if(response.data.error)
      yield put(updateLanguageFaild(response.data.msg));
     else
      yield put(updateLanguageSuccess(response.data.msg));
  }
  catch(error){
    yield put(updateLanguageFaild(error));
  }
}
function* addLanguage() {
  yield takeLatest("ADD_LANGUAGE_REQUESTED", addLang);
}
function* fetchLanguage() {
  yield takeLatest("GET_LANGUAGE_REQUESTED", getLang);
}
function* updateLanguage(){
  yield takeLatest('UPDATE_LANGUAGE_REQUESTED',updateLang);
}
function* deleteLanguage(){
  yield takeLatest('DELETE_LANGUAGE_REQUESTED',deleteLang);
}
export { addLanguage, fetchLanguage, updateLanguage,deleteLanguage };
