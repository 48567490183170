import * as type from "../types";
export const addResourceRequested = (user) => ({
  type: type.ADD_RESOURCE_REQUESTED,
  payload: user,
});
export const addResourceSuccess = (u) => ({
  type: type.ADD_RESOURCE_SUCCESS,
  payload: u,
});
export const addResourceFaild = (errorMsg) => ({
  type: type.ADD_RESOURCE_FAILD,
  payload: errorMsg,
});
export const getResourceRequested = () => ({
  type: type.GET_RESOURCE_REQUESTED,
});
export const getResourceSuccess = (resource) => ({
  type: type.GET_RESOURCE_SUCCESS,
  payload: resource,
});
export const getResourceFaild = (errorMsg) => ({
  type: type.GET_RESOURCE_FAILD,
  error: errorMsg,
});
export const deleteResourceRequested = (delResource) => ({
  type: type.DELETE_RESOURCE_REQUESTED,
  payload: delResource,
});
export const deleteResourceSuccess = (delResource) => ({
  type: type.DELETE_RESOURCE_SUCCESS,
  payload: delResource,
});
export const deleteResourceFaild = (errorMsg) => ({
  type: type.DELETE_RESOURCE_FAILD,
  payload: errorMsg,
});
export const updateResourceRequested = (updateResource) => ({
  type: type.UPDATE_RESOURCE_REQUESTED,
  payload: updateResource,
});
export const updateResourceSuccess = (update) => ({
  type: type.UPDATE_RESOURCE_SUCCESS,
  payload: update,
});
export const updateResourceFaild = (errorMsg) => ({
  type: type.UPDATE_RESOURCE_FAILD,
  payload: errorMsg,
});
