import axios from "axios";
import { takeLatest, put, call } from "redux-saga/effects";
import {
  addChannelFaild,
  addChannelSuccess,
  deleteChannelFaild,
  deleteChannelSuccess,
  getChannelFaild,
  getChannelSuccess,
  updateChannelFaild,
  updateChannelSuccess,
} from "../actions/channels";
import { baseUrl } from "../../utils/index";

//add category to database
function addChannelApi({ values }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.post(`${baseUrl}/channels/`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* addChannel({ payload: { values } }) {
  try {
    const response = yield call(addChannelApi, { values });
    if (response.data.error) yield put(addChannelFaild(response.data.msg));
    else yield put(addChannelSuccess(response.data.msg));
  } catch (error) {
    yield put(addChannelFaild(error));
  }
}
//fetch channel from database
function getChannelApi() {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.get(`${baseUrl}/channels/`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* getChannel() {
  try {
    const response = yield call(getChannelApi);
    if (response.data.error) yield put(getChannelFaild(response?.data?.msg));
    else yield put(getChannelSuccess(response?.data?.data));
  } catch (error) {
    yield put(getChannelFaild(error?.message));
  }
}
function updateChannelApi({ values, id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.put(`${baseUrl}/channels/${id}`, values, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* updateChannel({ payload: { values, id } }) {
  try {
    const response = yield call(updateChannelApi, { values, id });
    if (response.data.error) yield put(updateChannelFaild(response.data.msg));
    else yield put(updateChannelSuccess(response.data.msg));
  } catch (error) {
    yield put(updateChannelFaild(error));
  }
}

function delChannelApi({ id }) {
  const token = localStorage.getItem("userInfo")
    ? JSON.parse(localStorage.getItem("userInfo")).token
    : null;
  return axios.delete(`${baseUrl}/channels/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
function* deleteChannel({ payload: { id } }) {
  try {
    const response = yield call(delChannelApi, { id });
    if (response.data.error) {
      yield put(deleteChannelFaild(response.data.msg));
    } else {
      yield put(deleteChannelSuccess(response.data.msg));
    }
  } catch (error) {
    yield put(deleteChannelFaild(error));
  }
}
function* createChannel() {
  yield takeLatest("ADD_CHANNEL_REQUESTED", addChannel);
}
function* fetchChannel() {
  yield takeLatest("GET_CHANNEL_REQUESTED", getChannel);
}
function* updatedChannel() {
  yield takeLatest("UPDATE_CHANNEL_REQUESTED", updateChannel);
}
function* deletedChannel() {
  yield takeLatest("DELETE_CHANNEL_REQUESTED", deleteChannel);
}
export { createChannel, fetchChannel, updatedChannel, deletedChannel };
