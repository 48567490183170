import * as type from "../types";
export const getSeasonRequested = () => ({
  type: type.GET_SEASON_REQUESTED,
});
export const getSeasonSuccess = (cate) => ({
  type: type.GET_SEASON_SUCCESS,
  payload: cate,
});
export const getSeasonFaild = (errorMsg) => ({
  type: type.GET_SEASON_FAILD,
  error: errorMsg,
});


